<template>
  <v-card class="organization-item">
    <v-card-title class="justify-start pointer" @click="$emit('view', item)">
      <Avatar :userId="item?.practitioner.id || ''" />
      <span class="ml-3">
        {{ item?.practitioner?.fullName || "" }}
      </span>
    </v-card-title>
    <v-row class="pa-3">
      <v-col cols="12" lg="6">
        <span class="heading-6"> Services provided by this worker </span>
        <ul>
          <li v-for="item in item.healthcareService" :key="item.code">{{ item.name || "" }}</li>
        </ul>
      </v-col>
      <v-col cols="12" lg="6">
        <span class="heading-6"> Roles which may perform </span>
        <ul>
          <li v-for="item in item.codeCoding" :key="item.code">{{ item.display || "" }}</li>
        </ul>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-end">
      <IconicButton left-icon="mdi-pencil-outline" size="small" text="Modify" @onClick="$emit('edit', item)" />
    </v-card-actions>
  </v-card>
</template>
<script>
import Avatar from "@/components/Avatar/Index.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";

export default {
  name: "PractitionerRoleInfo",
  components: { Avatar, IconicButton },
  props: {
    hideViewSchedule: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
