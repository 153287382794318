<template>
  <PageContainer>
    <div class="organization-item-container">
      <v-progress-linear v-if="isLoading" indeterminate />
      <PractitionerRoleInfo v-for="item in practitionerRoles" :key="item.id" :item="item" @edit="edit" />
    </div>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import PageContainer from "@/components/uikit/PageContainer.vue";
import { usePractitionerRoleStore } from "@/pinia-store/practitionerRole";
import { routesEnum } from "@/types/Routes.enum";
import { calendarValues } from "@/utils/constants";
import { scrollToTop } from "@/utils/scrollToBottom";
import PractitionerRoleInfo from "@/views/Owner/Organizations/PractitionerRoles/PractitionerRoleInfo.vue";

export default {
  name: "PractitionerRoles",
  data() {
    return {
      isLoading: false,
      calendarValue: "week",
      currentDate: new Date(),
    };
  },
  components: { PractitionerRoleInfo, PageContainer },
  computed: {
    ...mapState(usePractitionerRoleStore, ["practitionerRoles"]),
    calendarValues() {
      return calendarValues;
    },
  },
  methods: {
    ...mapActions(usePractitionerRoleStore, ["setPractitionerRoles", "getPractitionerRoles", "setForm"]),
    add() {
      this.setForm({});
      this.$router.push({ name: routesEnum.addOrganization });
    },
    edit(item) {
      this.setForm(item);
      this.$router.push({
        name: routesEnum.addOrganizationPractitionerRoles,
        params: { id: item.id, organizationId: this.$route.params.organizationId },
      });
    },
    onMoreClick(e) {
      console.log("on more click", e);
    },
    onCalendarDateClick(e) {
      console.log("on onCalendarDateClick", e);
    },
    changeCalendarValue(e) {
      this.calendarValue = e;
      // this.$router.push({
      //   name: routesEnum.practitionerSchedule,
      //   query: { calendarValue: e, currentDate: this.currentDate },
      // });
      // this.getScheduledAppointments();
    },
    getEventColor(event) {
      return event.color;
    },
  },
  async mounted() {
    scrollToTop();
    this.isLoading = true;
    await this.getPractitionerRoles({ organization: this.$route.params.organizationId });
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.organization-item-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
</style>
